import { ChangeEventHandler } from 'react';
import * as React from 'react';

interface RadioProps {
  id: string;
  name: string;
  value: string | number | readonly string[] | undefined;
  currentValue: string | number | readonly string[] | undefined;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  label: string | React.ReactNode;
}

export default function Radio({
  id,
  name,
  value,
  currentValue,
  onChange,
  label,
}: RadioProps) {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="radio-label">
        <input
          type="radio"
          id={id}
          value={value}
          className="visually-hidden"
          onChange={onChange}
          name={name}
          checked={value === currentValue}
        />
        <span className="label">{label}</span>
      </label>
    </>
  );
}
