import React from 'react';
import { Toaster } from 'react-hot-toast';
import '../styles/main.scss';

interface Props {
  children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      {children}
      <Toaster
        toastOptions={{
          success: {
            iconTheme: {
              primary: '#22c55e',
              secondary: '#fff',
            },
          },
          error: {
            iconTheme: {
              primary: '#ef4444',
              secondary: '#fff',
            },
          },
        }}
      />
    </>
  );
}
