import * as React from 'react';
import { MdLink, MdShare } from 'react-icons/md';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import ShareSocials from './ShareSocials';
import { ShareProps } from './Share.types';
import './Share.scss';

export default function Share({
  siteUrl,
  title = 'Share this Poll',
  description = 'The more people who participate, the louder and more effective this message becomes.',
  onCopySuccess,
}: ShareProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [show, setShow] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const copyLink = async () => {
    setCopied(true);
    navigator.clipboard.writeText(siteUrl);

    if (typeof onCopySuccess === 'function') {
      onCopySuccess();
    }

    await new Promise((res) => setTimeout(res, 3000)); // eslint-disable-line no-promise-executor-return
    setCopied(false);
  };

  useOnClickOutside(ref, () => setShow(false));

  return (
    <div className="share">
      <div className="share__intro narrow">
        <div ref={ref} className="h5 share__title">
          <button
            type="button"
            className="share__title__button"
            onClick={() => setShow(!show)}
          >
            <span>{title}</span> <MdShare />
          </button>
          {show && (
            <div className="share__title__dropdown">
              <ShareSocials siteUrl={siteUrl} />
            </div>
          )}
        </div>
        <p>{description}</p>
      </div>
      <ShareSocials siteUrl={siteUrl} />
      <div className="share__link">
        <button type="button" onClick={copyLink} disabled={copied}>
          <MdLink />
          {copied ? 'Link copied!' : 'Copy link'}
        </button>
      </div>
    </div>
  );
}
