import * as React from 'react';
import { useAnimation, motion } from 'framer-motion';
import { ResultsProps, ResultsRowProps } from './Results.types';
import './Results.scss';

function ResultsRow({
  image,
  imageType,
  percent,
  count,
  color,
  countFormat,
}: ResultsRowProps) {
  const controls = useAnimation();

  React.useEffect(() => {
    if (!count) {
      return;
    }
    controls.set({ color });
    controls.start({
      scale: 1,
      transition: {
        type: 'spring',
        velocity: 50,
        stiffness: 700,
        damping: 80,
      },
    });
    controls.start({
      color: 'var(--color-p)',
      transition: {
        duration: 3,
      },
    });
  }, [count]);

  return (
    <li>
      <div className="count-results__count__row">
        <div
          className={`count-results__count__img ${
            imageType ? `count-results__count__img--${imageType}` : ``
          }`}
        >
          {image}
        </div>
        <div className="count-results__count__meter">
          <div
            className="count-results__count__meter__filled"
            style={
              {
                width: `${percent}%`,
                '--meter-color': color,
              } as React.CSSProperties
            }
          />
        </div>
        <div className="count-results__count__number">
          <span>
            <motion.span animate={controls} style={{ display: 'inline-block' }}>
              <strong>
                {countFormat === 'number' ? count : `${Math.round(percent)}%`}
              </strong>
            </motion.span>{' '}
            {countFormat === 'percent' ? 'of votes' : 'votes'}
          </span>
        </div>
      </div>
    </li>
  );
}

export default function Results({
  results,
  imageType = 'rounded',
  countFormat = 'number',
  showButton = true,
}: ResultsProps) {
  // const totalCount = results.reduce((acc, { count }) => acc + count, 0);
  const maxCount = results.reduce((acc, { count }) => Math.max(acc, count), 0);
  const maxCountDigit =
    maxCount && countFormat === 'number'
      ? Math.floor(Math.log10(maxCount)) + 1
      : 3;
  const maxCountBuffer = countFormat === 'number' ? 6 : 9;

  return (
    <div className="count-results">
      <div className="count-results__count">
        <div
          className="count-results__count__box"
          style={
            {
              '--meter-number-ch': `${maxCountDigit + maxCountBuffer}ch`,
            } as React.CSSProperties
          }
        >
          <ul>
            {results.map((result) => {
              const { image, percent, count, color } = result;
              return (
                <ResultsRow
                  key={`result-${color}`}
                  image={image}
                  imageType={imageType}
                  percent={percent}
                  count={count}
                  color={color}
                  countFormat={countFormat}
                />
              );
            })}
          </ul>
        </div>
      </div>
      {showButton && (
        <div className="count-results__button">
          <a href="#vote" className="button">
            Vote Now
          </a>
        </div>
      )}
    </div>
  );
}
