import facebook from './images/facebook.svg';
import twitter from './images/twitter.svg';
import linkedin from './images/linkedin.svg';
import instagram from './images/instagram.svg';
import email from './images/email.svg';
import { ShareSocialsProps } from './Share.types';

export default function ShareSocials({ siteUrl }: ShareSocialsProps) {
  const text = 'Whose leadership do we need most today?';

  return (
    <div className="share__socials">
      <ul>
        <li>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${siteUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="Facebook" width={32} height={64} />
          </a>
        </li>
        <li>
          <a
            href={`http://twitter.com/share?text=${text}&url=${siteUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} alt="Twitter" width={64} height={64} />
          </a>
        </li>
        {/* <li>
            <a href="">
              <img
                src={instagram}
                alt="Instagram"
                width={64}
                height={64}
              />
            </a>
          </li> */}
        <li>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${siteUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="LinkedIn" width={64} height={64} />
          </a>
        </li>
        <li>
          <a
            href={`mailto:?subject=${text}&body=${siteUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={email} alt="Email" width={64} height={64} />
          </a>
        </li>
      </ul>
    </div>
  );
}
